<template>
  <div class="container">
    <div class="container content">
      <v-card-title>
        <v-card-text class="text-center text-h4 font-weight-bold home-title"
          >Welcome To GEPI.io</v-card-text
        >
      </v-card-title>
      <!-- <v-alert text color="cyan darken-2" icon="mdi-alert-circle">
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <h2>Our Work</h2>
      <div id="gepi-centers-overview">
        <v-card
          href="/programs/?center=govex"
          class="content-panel box-two-fifth project-panel mt-4 mr-12"
          elevation="10"
        >
          <div class="govex-bar"></div>
          <v-card-title class="panel-title justify-center text-h6">
            <strong>GovEx</strong>
          </v-card-title>
          <div class="card-content">
            <div class="half-card">
              <v-card-text class="grey--text pb-1 font-weight-medium"
                ><strong>Total Program Cohorts</strong>:
                {{ govexProgramsCount || 0 }}
              </v-card-text>
              <v-card-text class="grey--text font-weight-medium"
                ><strong>Active Program Cohorts</strong>:
                {{ govexActiveProgramsCount || 0 }}
              </v-card-text>
            </div>
            <div class="half-card">
              <v-card-text class="grey--text pb-1 font-weight-medium"
                ><strong>Total Projects</strong>:
                {{ govexProjectsCount || 0 }}
              </v-card-text>
              <v-card-text class="grey--text font-weight-medium"
                ><strong>Active Projects</strong>:
                {{ govexActiveProjectsCount || 0 }}
              </v-card-text>
            </div>
          </div>
        </v-card>
        <v-card
          href="/programs/?center=bcpi"
          class="content-panel box-two-fifth project-panel mt-4 mr-12"
          elevation="10"
        >
          <div class="bcpi-bar"></div>
          <v-card-title class="panel-title justify-center text-h6">
            <strong>BCPI</strong>
          </v-card-title>
          <div class="card-content">
            <div class="half-card">
              <v-card-text class="grey--text pb-1 font-weight-medium"
                ><strong>Total Program Cohorts</strong>:
                {{ bcpiProgramsCount || 0 }}
              </v-card-text>
              <v-card-text class="grey--text font-weight-medium"
                ><strong>Active Program Cohorts</strong>:
                {{ bcpiActiveProgramsCount || 0 }}
              </v-card-text>
            </div>
            <div class="half-card">
              <v-card-text class="grey--text pb-1 font-weight-medium"
                ><strong>Total Projects</strong>:
                {{ bcpiProjectsCount || 0 }}
              </v-card-text>
              <v-card-text class="grey--text font-weight-medium"
                ><strong>Active Projects</strong>:
                {{ bcpiActiveProjectsCount || 0 }}
              </v-card-text>
            </div>
          </div>
        </v-card>
      </div>
      <div id="home-timeline" class="tabcontent">
        <timeline
          :colorCodeByCenter="true"
          timelineHeight="80vh"
          projectsEndPoint="projects?"
          updatesEndPoint="projects/update"
        />
      </div>
    </div>
  </div>
</template>
<style>
/* Page CSS */
/* .title-box {
  text-align: center;
  margin: 0px -50px;
  background: url("../assets/images/login-bg.svg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 130px;
  display: flow-root;
  padding: 40px 20px 0px 20px;
}
p,
h1 {
  color: white;
}
h2 {
  margin-top: 50px;
}

.v-application .text-h4 {
  word-break: break-word;
}
.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
}
.buttons a {
  flex-basis: 25%;
  flex-shrink: 0;
  cursor: pointer;
  min-width: 225px;
}
button.btn-quicklink {
  font-size: 1em;
  background: var(--aqua);
  color: var(--white);
  border: 0px;
  padding: 20px;
  width: 90%;
  margin: 10px 0px;
  border-radius: 5px;
  font-family: "roboto-bold";
  cursor: pointer;
}
button.btn-quicklink:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  -webkit-transition: transform 250ms ease;
  transition: transform 250ms ease;
}
.chart-bar {
  width: 95%;
  height: 80px;
  display: inline-flex;
}
.govex {
  width: var(--govex_bar);
  height: 100%;
  background-color: var(--govex);
  float: left;
  text-align: center;
  color: var(--white);
}
.bcpi {
  width: var(--bcpi_bar);
  height: 100%;
  background-color: var(--bcpi);
  float: left;
  text-align: center;
  color: var(--white);
}
.chart-text {
  font-size: 1.7em;
  font-family: "roboto";
  margin-top: 10px;
}
.chart-text span {
  display: block;
  font-size: 0.8em;
}
#stats {
  font-family: "roboto";
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
}
.stat {
  font-family: "roboto-bold";
  display: inline-flex;
}
h2.map-header {
  margin-top: 10px;
  margin-bottom: 0px;
} */
.govex-bar {
  width: 100%;
  height: 25px;
  margin-left: 0px;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 0px;
  background-color: var(--govex);
}
.bcpi-bar {
  width: 100%;
  height: 25px;
  margin-left: 0px;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 0px;
  background-color: var(--blue);
}
/* .worked-with-section {
  color: var(--grey);
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 300px;
  margin-top: -110px;
} */
.stats {
  color: var(--aqua);
  font-size: 0.875rem;
  font-weight: 800;
}
/* .update-status-bar {
  width: 100%;
  height: 10px;
  margin-left: 0px;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 0px;
}
.update-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  white-space: normal;
}

.program-icon {
  height: 16px;
  width: 30px;
  margin: 0px auto;
  padding: 0px 10px;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: var(--aqua);
  mask-image: url("../assets/icons/programs.svg");
}

.icon-box {
  background-color: rgba(166, 248, 246, 0.4);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

img {
  border-radius: 50%;
  vertical-align: middle;
}
div#program-updates {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 30px;
}

div#program-updates .v-card {
  flex-basis: 50%;
}

div#program-updates .v-card__title {
  display: flex;
  flex-flow: initial;
}

div#program-updates .v-card__title a {
  text-align: left;
} */
div#gepi-centers-overview .card-content .v-card__text {
  padding-top: 5px;
}
div#home-timeline {
  padding: 3vw 0vw 20px 10px;
  max-width: 95.5%;
}
.home-title {
  white-space: pre-wrap;
  word-break: break-word;
}
@media (max-width: 1050px) {
  div#gepi-centers-overview .content-panel {
    margin-right: 0px !important;
  }
  div#program-updates {
    flex-flow: row wrap;
  }
  div#program-updates .v-card {
    flex-basis: 100% !important;
  }
  div#program-updates .v-card:not(:first-child) {
    flex-basis: 100% !important;
    margin-top: 40px !important;
  }
  #gepibot {
    width: 35px !important;
    border: 2px solid var(--aqua) !important;
  }
}
</style>
<script>
// import Status from "../components/Status";
// import countries from "@/countries.json";
// import { VueShowdown } from "vue-showdown";
// import OrganizationsTable from "../components/OrganizationsTable";
import Timeline from "../components/Timeline.vue";

export default {
  components: { Timeline },
  title() {
    return `Home - GEPI.io`;
  },
  data() {
    return {
      stats: [],
    };
  },
  created() {
    this.getStats();
  },

  computed: {
    // centerCount: function() {
    //   const counts = {};
    //   this.projects.map((p) => {
    //     counts[p.award_center] = (counts[p.award_center] || 0) + 1;
    //   });
    //   return counts;
    // },
    // createBars: function() {
    //   const total = this.projects.length;
    //   return {
    //     "--govex_bar":
    //       (100 * (this.centerCount.GovEx / total)).toFixed(1) + "%",
    //     "--bcpi_bar": (100 * (this.centerCount.BCPI / total)).toFixed(1) + "%",
    //   };
    // },
    // orgStatistics: function() {
    //   const orgs = this.allOrgs;
    //   let citySet = new Set();
    //   let stateSet = new Set();
    //   let countrySet = new Set();
    //   orgs.map((o) => {
    //     o.city !== "None" && o.city !== "" && citySet.add(o.city);
    //     o.state_region !== "None" &&
    //       o.state_region !== "" &&
    //       stateSet.add(o.state_region);
    //     o.country_region !== "None" &&
    //       o.country_region !== "" &&
    //       countrySet.add(o.country_region);
    //   });
    //   return {
    //     cityStat: citySet.size,
    //     stateStat: stateSet.size,
    //     countryStat: countrySet.size,
    //     orgs_data: orgs,
    //   };
    // },
    // geoData: function() {
    //   const stateData = [["State", "Projects"]];
    //   this.allOrgs.map((o) => {
    //     o.state_region !== "None" &&
    //       o.state_region !== "" &&
    //       o.country_region === "United States" &&
    //       o.phase === "ACT" &&
    //       stateData.push(["US-" + o.state_region, o.projects]);
    //   });

    //   return stateData;
    // },
    orgCount: function() {
      return this.organizations.length;
    },

    // govexGrantsCount() {
    //   let s = this.stats;
    //   return s.length && s[1].grants;
    // },
    // bcpiGrantsCount() {
    //   let s = this.stats;
    //   return s.length && s[0].grants;
    // },
    govexProgramsCount() {
      let s = this.stats;
      return s.length && s[1].programs;
    },
    bcpiProgramsCount() {
      let s = this.stats;
      return s.length && s[0].programs;
    },
    govexProjectsCount() {
      let s = this.stats;
      return s.length && s[1].projects;
    },
    bcpiProjectsCount() {
      let s = this.stats;
      return s.length && s[0].projects;
    },
    govexActiveProgramsCount() {
      let s = this.stats;
      return s.length && s[1].active_programs;
    },
    bcpiActiveProgramsCount() {
      let s = this.stats;
      return s.length && s[0].active_programs;
    },
    govexActiveProjectsCount() {
      let s = this.stats;
      return s.length && s[1].active_projects;
    },
    bcpiActiveProjectsCount() {
      let s = this.stats;
      return s.length && s[0].active_projects;
    },
    // govexPeopleCount() {
    //   let s = this.stats;
    //   return s.length && s[1].people;
    // },
    // bcpiPeopleCount() {
    //   let s = this.stats;
    //   return s.length && s[0].people;
    // },
    // govexOrgsCount() {
    //   let s = this.stats;
    //   return s.length && s[1].orgs;
    // },
    // bcpiOrgsCount() {
    //   let s = this.stats;
    //   return s.length && s[0].orgs;
    // },
  },

  methods: {
    getStats: function() {
      this.$api.get("contacts/organizations/stats").then((response) => {
        this.stats = response.data;
      });
    },
  },
};
</script>
